@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    text-align: left;
    background-color: #EFF2FF;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 1.125rem
}

@font-face {
    font-family: 'bang-tutor';
    src:
        url(/static/media/bang-tutor.c03d25e5.ttf) format('truetype'),
        url(/static/media/bang-tutor.8249b2d5.woff) format('woff'),
        url(/static/media/bang-tutor.c8daa2d0.svg#bang-tutor) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'carley-co-regular';
    src:
        url(/static/media/carley-co-regular.2530a9a9.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'GT-Walsheim-Regular';
    src:
        url(/static/media/GT-Walsheim-Regular.2babce67.ttf) format('truetype'),
        url(/static/media/GT-Walsheim-Regular.4c944630.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'GT-Walsheim-Bold';
    src:
        url(/static/media/GT-Walsheim-Bold.8c540246.ttf) format('truetype'),
        url(/static/media/GT-Walsheim-Bold.eeac2323.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.fb {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'bang-tutor' !important;
    speak-as: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fb-Activities:before {
    content: "\e900";
}

.fb-avatar-status:before {
    content: "\e903";
    color: #008000;
}

.fb-bell:before {
    content: "\e904";
    color: #4c52bc;
}

.fb-book:before {
    content: "\e905";
    color: #43283a;
}

.fb-calendar:before {
    content: "\e906";
    color: #43283a;
}

.fb-calendar2:before {
    content: "\e907";
    color: #5a3f60;
}

.fb-contract:before {
    content: "\e908";
    color: #43283a;
}

.fb-countdown:before {
    content: "\e909";
    color: #43283a;
}

.fb-Date-time:before {
    content: "\e90a";
    color: #c5c5c5;
}

.fb-Emma:before {
    content: "\e90b";
}

.fb-filters:before {
    content: "\e90c";
}

.fb-financial:before {
    content: "\e90d";
    color: #43283a;
}

.fb-gift-card:before {
    content: "\e90e";
    color: #43283a;
}

.fb-History:before {
    content: "\e911";
}

.fb-home:before {
    content: "\e912";
}

.fb-My-Account:before {
    content: "\e913";
}

.fb-My-profile:before {
    content: "\e914";
}

.fb-other:before {
    content: "\e915";
    color: #43283a;
}

.fb-payment-collections:before {
    content: "\e916";
    color: #43283a;
}

.fb-prepaid:before {
    content: "\e917";
    color: #43283a;
}

.fb-request-call:before {
    content: "\e91a";
}

.fb-Session-Report:before {
    content: "\e91b";
}

.fb-setting:before {
    content: "\e91c";
}

.fb-watch:before {
    content: "\e91d";
    color: #c5c5c5;
}

/* ----- Common css ====== */

.i-pad-button {
    display: none;
}

.sm-block {
    display: none !important;
}

.md-block {
    display: block !important;
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.navbar-vertical.navbar-expand-md.fixed-left {
    background-color: #43283a;
}


/*css mobile for top menu*/
.navbar-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.navbar-light .navbar-toggler {
    color: #43283a;
    border: 2px solid #fff;
}

.navbar-brand-img,
.navbar-brand>img {
    max-width: 100%;
}

.navbar-light .navbar-user {
    border-top-color: #e3ebf6;
}

.dropdown-toggle {
    white-space: nowrap;
}

.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
    font-size: .83333rem;
}

/* .avatar-online::before {
    background-color: #00d97e;
}

.avatar-offline::before,
.avatar-online::before {
    content: '';
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%;
} */

.avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rounded-circle {
    border-radius: 50% !important;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .375rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #6e84a3;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-divider {
    height: 0;
    margin: .75rem 0;
    overflow: hidden;
    border-top: 1px solid #edf2f9;
}

/*css mobile for top menu end*/

li.nav-item span {
    margin-left: 10px;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.navbar-nav .nav-link>.fb {
    display: inline-block;
    min-width: 1.75rem;
}

.icons img {
    margin-right: 15px;
    margin-top: -3px;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-light .navbar-toggler-icon {
    border-color: rgb(255 255 255);
}

.navbar-light .navbar-nav .nav-link {
    color: #FFFFFF;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
    padding: 1.1rem 1.5rem;
    font-size: 1.1rem;
}

.navbar-vertical .navbar-nav .nav-link {
    display: flex;
    align-items: center;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #BCAFBE;
}

.header {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.header-title {
    color: #43283a;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 5px;
}

.header-call {
    font-size: 25px;
    color: #000000;
    margin-right: 30px;
    margin-bottom: 0px;
}

.header-call span {
    font-size: 25px;
    color: #43283a;
    font-weight: bold;
}

.lift {
    background-color: #43283a;
    border-color: #43283a;
    border-radius: 50px;
    font-size: 24px;
    padding: 6px 35px;
    margin-right: 11px;
    display: inline-flex;
    align-items: center
}

a.btn.btn-primary.lift:hover {
    color: #fff;
    background-color: #43283a;
    border-color: #43283a;
}
.right-side {
    display: flex;
    align-items: baseline;
}


.lift i.fb.fb-request-call {
    padding-right: 14px;
    font-size: 30px;
}

.centers {
    display: flex;
    align-items: center;
}

.text-icons img {
    margin-right: 12px;
}

tr.bang-time {
    background-color: #EFF2FF;
}

.table td,
.table th {
    padding: 1rem;
    vertical-align: top;
    border-top: 1px solid #f3f3f3;
    color: #2D2D2D;
    font-size: 18px;
}

.table td {
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    font-size: 14.4px;
}

.table td:first-child {
    border-radius: 10px;
    border-left: 1px solid #f3f3f3;
}

.table td:last-child {
    border-radius: 10px;
    border-right: 1px solid #f3f3f3;
}

/*info button*/

.info-button {
    color: inherit;
    text-decoration: none;
    padding: 10px 0;
}

.info-icon {
    margin-right: 10px;
}

.table thead th {
    border-bottom: none;
    font-size: 14.4px;
}

table.dataTable {
    margin-top: unset !important;
    margin-bottom: unset !important;
    border-spacing: 0px 8px !important;
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate,
div.dataTables_wrapper div.dataTables_filter {
    display: none;
}

a.filter {
    padding: 10px 15px;
    background-color: #eff2ff;
    font-size: 18px;
    border-radius: 4px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 1rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABHNCSVQICAgIfAhkiAAAApZJREFUSEvNVk12EkEQrhpcqBuTEwRPIO4NjCcI2QU2dk7geALwBJITpN2Q7CQncAgeYLwBOYG4ARcy7VcDPa+Z12FmfNEXdlNVXV/9fFQVk+d39kaFQYO+Ukrn45nWrknvWHU5oC8mpdOrmZ4UdBF0g3RNp9ffdOzzzT5hv62GxDQwhqZXtzp0bayODH0c3+rhDmBbxczU8emsXQ6IyCMK6ASCFpQHvkBqyhaGKGFDEwR2sQPY66gEQK9qOqxjniyZ3k5iveBeW41QhveI5ienFKWG5uiDguwdZN/NmiLXc64z9Bl93OkvN2gkgaMVmS5gapogk72A7ALtidhm55JAygvHn+QhjJSvh7D/ANKMCj3UEqhLNksy2CXjqX7N/Y5CIkRI+VBStg6Eqb+eUOLKRNcN1cHT39TysdCnE9lzQz/kLQA5B5SPOk2pY2uTejyAUoZnKZ+sAnNjS5r1gtiMZ5c3NruizPdObEsz7B8rhf/kpW2+03jaJyu+s4E9PsCMWWvqLhs0sSXNohc275H53lUqaR0GVrEtLWlV0mwy4g64JPOXyHCybJhp8b9bCliFNOL/nhm8wBB56YI+CKDdmTKDsY6SLEOmlszN4h4tBaxCmhzQ2ZlYBN59WApYhQi1AMFwVGIhF4J3lhZJY78lEDt98jMEjmTRbiq6Xd6e08Qm4QXMSbM9I/JveeU485FGduiKKSwydS9g1kMs4yWWpzy0PS3+8eVbMnVb4FtpZ6Fqis11rOf/ZT2hEguU22A9HQrgHOBHvrOvCnnKbJzBfwfAptw0m7NAGp/y0F0/Zc7K9FuwAexa9lzh7QkgLDsqc/C3ehkOIFIzu9rEyYb2uK7ArgcGvkNm8SqgyLL2n90x91XjD/liSDsOTlEHAAAAAElFTkSuQmCC) no-repeat;
    width: 30px;
    height: 30px;
    border-width: thin;
    margin-top: 8px;
}

.filter i {
    margin-left: 30px;
}

.content {
    margin-bottom: 3rem;
}

.big {
    font-size: 24px;
    color: #43283a;
    font-weight: bold;
}

.card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #ffffff;
    border-bottom: unset;
    padding: unset;
}

.card-header>:first-child {
    flex: 1 1;
    font-size: 24px;
    color: #43283a;
    font-weight: bold;
}

.card {
    margin-bottom: 1.5rem;
    border-color: #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    padding: 20px;
    border-radius: 1px solid #EAEAEA;
}

.card-header-title {
    margin-bottom: 0;
}

.active-summery {
    padding: 15px;
    /* background: url('../images/New Icons/summery-bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #171717;
    background-position: center center;
    border-radius: 18px;
    display: flex;
    align-items: center;
    height: 150px;
    margin-bottom: 1.8rem;
}

.active-summery .icons i {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    font-size: 25px;
}

.active-hours h3,
.active-hours span {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
}

.active-hours {
    margin-left: 20px;
    display: block;
}

.active-hours span {
    font-size: 30px;
}

.active-summery:hover {
    background-color: #DB1D1F;
    /* background: url('../images/New Icons/red-bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.Active-account-summery,
.Enrollment-part {
    background-color: #fff;
    padding: 30px 20px 5px 20px;
    margin: 20px;
}

.active-summery:hover i:before {
    color: red;
}

.Summary .col {
    max-width: 20%;
}

.thumbail .col {
    flex-basis: 20%;
    flex-grow: 0;
}

h4.ac-summery-title,
.Active-summery-title {
    color: #43283a;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 30px;
}

h4.Active-summery-title {
    border-bottom: 1px solid #43283a;
    padding-bottom: 25px;
}

.Active-Enrollment p {
    text-align: center;
    color: #999999;
    font-size: 21px;
}

.navbar-brand .black-logo {
    display: none;
}


/* My Imformations page css*/

.tutor-Imformations .btn-link::after {
    content: '\2212';
    width: 35px;
    font-size: 35px;
    text-align: center;
    border-radius: 5px;
    top: 22px;
    right: 95px;
    position: absolute;
    color: #43283a;
    transition: all 0.8s ease;
}

.tutor-Imformations .btn-link.collapsed::after {
    content: '\002B';
    transition: all 0.8s ease;
}

.tutor-Imformations .btn-link {
    color: #43283a;
    font-size: 26px;
    font-weight: bold;
    text-decoration: none;
}

.tutor-Imformations .card-header {
    padding: 30px 8px;
}

.col-form-label {
    font-weight: 400;
    font-size: 20px;
    color: #707070;
    margin-bottom: 15px;
    max-width: 100%;
}

.form-control {
    border: 1px solid #BCAFBE;
    border-radius: 4px;
    color: #a29e9e;
    width: 90%;
}

span.required {
    color: #AD175B;
    font-size: 20px;
    font-weight: bold;
}

form {
    margin-left: 10px;
}

form.Session-Report-model {
    margin-left: 0px !important;
}

.Session-Report-model .modal-body {
    padding: 1rem 1rem 1rem 1rem;
}

.btn-bang-save {
    background-color: #F5F5F5;
    border-radius: 25px;
    border: 1px solid #43283a;
    text-align: center;
    color: #43283a;
    margin-top: 2rem;
    margin-left: 1rem;
    font-size: 25px;
    width: 142px;
    height: 55px;
}

.Quick.Filter {
    font-size: 20px;
    margin-top: 0px;
}

.Quick.Filter:hover {
    background-color: #43283a;
    color: #F5F5F5;
}

.btn-bang-save:hover {
    background-color: #43283a;
    color: #F5F5F5;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    width: 100%;
    text-align: center;
    font-size: 30px;
    color: #43283a;
}


#chartContainer {
    height: 370px;
    width: 100%;

}

.graph-buttons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

a.canvasjs-chart-credit {
    display: none;
}

.Used-Hours {
    padding: 10px 43px;
    background-color: #fff;
    color: #000;
    border: 1px solid #E1E1E1;
    font-size: 16px;
    position: relative;
}

.Used-Hours:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #E1E1E1;
}

span.used-red {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    background-color: #43283a;
    border-radius: 50px;
    margin-left: -23px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

span.used-red.blue {
    background-color: #DA1A2F;
}

/* dataTables CSS modification & positioning */
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    right: 0 !important;
    content: "" !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 0 !important;
    content: "" !important;
}

table.dataTable thead th {
    position: relative;
    background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
    position: absolute !important;
    top: 18px !important;
    right: 12px !important;
    display: block !important;
    font-family: "Font Awesome 5 Free";
    opacity: 1;
}

table.dataTable thead th.sorting:after {
    content: "\f0dc" !important;
    color: #000 !important;
    font-size: 0.8em !important;
    padding-top: 0.12em !important;
}

table.dataTable thead th.sorting_asc:after {
    content: "\f0de" !important;
}

table.dataTable thead th.sorting_desc:after {
    content: "\f0dd" !important;
}

.notification-badge.notification-badge-number {
    top: -7px;
    right: -8px;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: middle;
    font-size: .7rem;
    line-height: 1.2rem;
    background-color: #E02020 !important;
}

.notification-badge {
    position: absolute;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}

#notification {
    background-color: white;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 25px;
    text-decoration: none;
    position: relative;
    margin-top: -18px;
    line-height: normal;
}

.dropdown-toggle::after {
    display: none;
}

.avatar {
    display: inline-block;
    position: relative;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border: #dee2e6;
    border-radius: 50%;
    background: #b8b8b8d9;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    /* line-height: 3rem; */
}

.text-sm {
    font-size: .7875rem !important;
}

.fw-bold {
    font-weight: 700 !important;
    color: #495057;
}

a#notification\ mobile {
    display: none;
}

.card.total-hours {
    min-height: 409px;
    height: 100%;
}

.bang-body form {
    margin-left: 0px;
}

.modal-body.bang-body {
    padding: 20px 20px 0px 20px;
}

.Active-Enrollment {
    margin-left: 10px;
}

/*radio-image css*/
.image-radio {
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 4px solid transparent;
    margin-bottom: 0;
    outline: 0;
    padding: 7px 7px 0px 0px;
    z-index: 8;
    transition: -webkit-transform 0.1s ease-out 0s;
    transition: transform 0.1s ease-out 0s;
    transition: transform 0.1s ease-out 0s, -webkit-transform 0.1s ease-out 0s;
}

.image-radio input[type="radio"] {
    display: none;
}

.image-radio .glyphicon {
    position: absolute;
    color: #4A79A3;
    background-color: #fff;
    padding: 10px;
    top: 0;
    right: 0;
}

.image-radio-checked .glyphicon {
    display: block !important;
}

.image-radio img {
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid transparent;
    display: inline-flex;
}

.image-radio:hover,
.image-radio:focus,
.image-radio:focus-within {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    z-index: 11;
}

/* Profile Dropdown  16/08/21 ====*/

.profile-dropdown {
    padding: 0;
    margin-left: 11px;
    margin-top: -20px;
}

/*===== Login page css =====*/

.login-section {
    display: table;
    height: 100vh;
    width: 100%;
}

.login-content {
    display: table-cell;
    height: 100vh;
    width: 100%;
}

.login-banner {
    /* background: url(../images/banner-img.png); */
    height: 100%;
    width: 100%;
    display: table;
    z-index: 207;
    position: relative;
    background-color: #43283a;
}

.banner-content {
    height: 100%;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    background-size: 100% 90% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.content-box {
    width: 60%;
    position: absolute;
    top: 130px;
    left: 78px;
}

.login-title {
    font-size: 87.5px;
    color: #fff;
    font-weight: 500;
    text-align: left;
    font-family: 'carley-co-regular';
    text-transform: uppercase;
    line-height: normal;
}

.login-subtitle {
    color: #fff;
    font-size: 18.75px;
    font-weight: normal;
    line-height: 34px;
    margin: 20px 0;
    font-family: 'GT-Walsheim-Regular';
}

.login-subtitle span.f-700 {
    font-weight: 700 !important;
    font-family: 'GT-Walsheim-Bold';
}

.btn-download {
    background-color: #efe445;
    padding: 8px 35px;
    border-radius: 0;
    line-height: normal;
    font-weight: 700;
    color: #43283a;
    font-size: 14.58px;
    font-family: 'GT-Walsheim-Bold';
}

/*======= Form css =======*/

.log-box {
    display: table;
    height: 100%;
    width: 100%;
}

.login-box {
    padding: 130px 34px 0;
    height: 100vh;
    display: table-cell;
    width: 100%;
    vertical-align: top;
}

/* .login-box img{
    width:150px;
} */

.check_box {
    margin-top: 20px;
}

.login-form {
    margin: 0 !important;
    padding: 44px 50px;
}

.form-title {
    text-align: center;
    font-size: 35px;
    margin-top: 30px;
    font-family: 'Poppins', sans-serif;
}

.login-form label.label-control {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    top: 5px;
    left: 18px;
    z-index: 99;
}

.login-form .form-group {
    margin-bottom: 10px;
    position: relative;
}

.login-form .form-control {
    height: 44px;
    border: 1px solid #333333;
    border-radius: 0;
    width: 100%;
    padding-left: 50px;
    color: #000000;
    font-family: 'GT-Walsheim-Regular';
}

.login-form .form-control::-webkit-input-placeholder {
    color: rgb(0 0 0);
    font-size: 16px;
    font-family: 'GT-Walsheim-Regular';
}

.login-form .form-control::placeholder {
    color: rgb(0 0 0);
    font-size: 16px;
    font-family: 'GT-Walsheim-Regular';
}

.positional-box {
    position: relative;
}

.login-form .btn-pass-view {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2%;
    height: 44px;
    font-size: 20px;
}

.error-text {
    color: #E02020;
    font-size: 12px;
    margin-top: 5px;
}

.button-box .btn-submit {
    background-color: #43283a;
    display: block;
    color: #fff;
    font-size: 20.65px;
    width: 164px;
    text-align: center;
    font-weight: bold;
    border-radius: 0;
    margin: 0 auto;
    height: 45px;
    font-family: 'GT-Walsheim-Bold';
    text-transform: uppercase;
}

.btn-forgot-pass {
    float: right;
    font-family: 'Poppins', sans-serif;
    margin-top: 15px;
    font-size: 20px;
}

/* ====== Checkbox Css ======== */

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    -webkit-clip-path: inset(100%);
            clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.form__check:not(:last-child) {
    margin-bottom: 10px;
}

/* Check */
.check {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.check__text {
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    font-family: 'GT-Walsheim-Regular';
}

.check__box {
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: hidden;
    margin-right: 10px;
    border: 1px solid #000;
    background-color: #fff;
}

.check__input:checked+.check__box {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAABXElEQVRIiWN8/8RVgIGBwZGB+mATCwMDgyIDA8M6GhjOxYQhREUwajhWMDgN//ePgSEt+wnDspUfMOQoNnz+oncM5y58Z9iw+SOGHEWGX776g2Hm3LcMbKyMDK0NEhjyBA3//5+B4dfv/xjiX7/+Y6hpeAEOltwsEQZVFXYMNQQNnz3vLUNq1hOGL1/+oYh39LxiePrsN4OVBRdDRKgAhj6iDN938CvD1Ws/GArLnjH8+AHxwY5dnxm27/rMICTIzNBQLcHAyIihjTjDp0+SZlBSYGM4f/E7Q0HpU4Z7D34xtPe8Ass11IgzCAkxY+gh2nBBAWaGKf3SDDLSrAxnzn1niIh9CA5vUFBYWXBjqMcGcBoOAmJiLAyzpsiALQBFoIoyO0NelgiGOlwAr+HIFigpsoGTHRsbgYBGAqDKwpCBgeEchgzlYLQ8HwDDwVH//okr8UmASCAos/s/AGa7ZVsQtrLDAAAAAElFTkSuQmCC) center/cover no-repeat;
    background-color: #fff;
    border: 1px solid #efe445;
    background-size: 20px;
}

.check__input:disabled~.check__text {
    cursor: default;
}


/* Accordian css */

.btn-pass-view.focus,
.btn-pass-view:focus {
    outline: 0;
    box-shadow: none;
}

/* accordion hide collapse icon */



/* table css */

.convenient .rdt_TableCol:nth-child(1),
.convenient .rdt_TableCell:nth-child(1) {
    min-width: 13rem;
}

.convenient .rdt_TableCol:nth-child(2),
.convenient .rdt_TableCell:nth-child(2) {
    min-width: 6rem;
}

.convenient .rdt_TableCol:nth-child(3),
.convenient .rdt_TableCell:nth-child(3) {
    min-width: 8rem;
}

.convenient .rdt_TableCol:nth-child(4),
.convenient .rdt_TableCell:nth-child(4) {
    min-width: 7rem;
}

.convenient .rdt_TableCol:nth-child(5),
.convenient .rdt_TableCell:nth-child(5) {
    min-width: 7rem;
}

.convenient .rdt_TableCol:nth-child(6),
.convenient .rdt_TableCell:nth-child(6) {
    min-width: 10rem;
}

.convenient .rdt_TableCol:nth-child(7),
.convenient .rdt_TableCell:nth-child(7) {
    min-width: 7rem;
}

/* .convenient .rdt_TableCol:nth-child(8), .convenient .rdt_TableCell:nth-child(8) {
    min-width: 10rem;
    max-width: fit-content;
} */

.profile-dropdown.focus,
.profile-dropdown:focus {
    outline: 0;
    box-shadow: none;
}

.no-wrap {
    white-space: nowrap;
}

.upcomming-session .rdt_TableCol:nth-child(1),
.upcomming-session .rdt_TableCell:nth-child(1) {
    min-width: 8rem !important;
}

.upcomming-session .rdt_TableCol:nth-child(2),
.upcomming-session .rdt_TableCell:nth-child(2) {
    min-width: 7rem !important;
}

.upcomming-session .rdt_TableCol:nth-child(3),
.upcomming-session .rdt_TableCell:nth-child(3) {
    min-width: 8rem !important;
}

.upcomming-session .rdt_TableCol:nth-child(4),
.upcomming-session .rdt_TableCell:nth-child(4) {
    min-width: 7rem !important;
}

.upcomming-session .rdt_TableCol:nth-child(5),
.upcomming-session .rdt_TableCell:nth-child(5) {
    min-width: 7rem !important;
}

.date-time-text {
    display: flex;
}

.date-time-text span {
    display: inline-block;
}

.date-time-text span img {
    width: 18px;
    margin-right: 5px;
}

.rdt_TableRow {
    border: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-radius: 5px;
    margin-top: 10px;
}

.rdt_TableHeadRow {
    background-color: #EFF2FF !important;
}

.bHWloc div:first-child {
    white-space: normal !important;
    line-height: 17px;
}

/* .rdt_TableRow,
.rdt_TableHeadRow{
    padding:0 5px;
} */

/* css for datatable modal display */
a.view-note {
    padding: 10px 15px;
    background-color: #eff2ff;
    font-size: 18px;
    border-radius: 4px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 1rem;
}

.view-note i {
    margin-left: 1px;
}

.recentsession-table .rdt_TableCol:nth-child(1),
.recentsession-table .rdt_TableCell:nth-child(1) {
    min-width: 13.5rem;
}

.recentsession-table .rdt_TableCol:nth-child(2),
.recentsession-table .rdt_TableCell:nth-child(2) {
    min-width: 6rem;
}

.recentsession-table .rdt_TableCol:nth-child(3),
.recentsession-table .rdt_TableCell:nth-child(3) {
    min-width: 8rem;
}

.recentsession-table .rdt_TableCol:nth-child(4),
.recentsession-table .rdt_TableCell:nth-child(4) {
    min-width: 7rem;
}

.recentsession-table .rdt_TableCol:nth-child(5),
.recentsession-table .rdt_TableCell:nth-child(5) {
    min-width: 7rem;
}

.recentsession-table .rdt_TableCol:nth-child(6),
.recentsession-table .rdt_TableCell:nth-child(6) {
    min-width: 9rem;
}

.recentsession-table .rdt_TableCol:nth-child(7),
.recentsession-table .rdt_TableCell:nth-child(7) {
    min-width: 6rem;
}

.recentsession-table .rdt_TableCol:nth-child(8),
.recentsession-table .rdt_TableCell:nth-child(8) {
    min-width: 4rem;
}

.filter-datepicker {
    width: 100% !important;
    color: #495057 !important;
}

.rdt_TableHeadRow {
    border-color: transparent !important;
}

/* ====== Safari Css ======== */

input::-webkit-credentials-auto-fill-button {
    -webkit-mask-image: none !important;
    -webkit-mask-size: 0 !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
}

/*==========================*/
/*============ 01/09/2021 ==============*/

.iVrjCb div:first-child {
    white-space: normal !important;
    overflow: visible !important;
    text-overflow: inherit !important;
    line-height: 17px;
}

/* =============== Media queries ================== */

@media (min-width:1025px) {
    .navbar-vertical.navbar-expand-md.fixed-left {
        left: 0;
        border-width: 0 1px 0 0;
    }

    .navbar-vertical.navbar-expand-md {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: 250px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        /* overflow-y: auto; */
    }

    .navbar-vertical.navbar-expand-md .navbar-brand {
        display: block;
        text-align: center;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .navbar-vertical.navbar-expand-md .navbar-collapse {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
    }

    .navbar-vertical.navbar-expand-md .navbar-nav {
        flex-direction: column;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }

    .navbar-vertical.navbar-expand-md>[class*=container] {
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-vertical.navbar-expand-md .navbar-collapse>* {
        min-width: 100%;
    }

    .navbar-vertical.navbar-expand-md.fixed-left:not([style*="display: none"])~.main-content {
        margin-left: 250px;
        padding: 0px 15px;
    }
}

/*media screen start*/

@media (min-width: 1241px) and (max-width: 1500px) {
    .thumbail .col {
        flex-basis: 25%;
        flex-grow: 0;
    }
}

@media (min-width: 768px) and (max-width: 1500px) {
    .table td {
        font-size: 12px !important;
    }

    .text-icons img {
        margin-right: 5px;
    }

    .Used-Hours {
        padding: 10px 30px;
        font-size: 14px;
    }
}

@media (min-width: 1420px) and (max-width:1800px) {
    .card.total-hours {
        min-height: 444px !important;
    }

}

@media (min-width: 1200px) and (max-width: 1420px) {



    .card.total-hours {
        min-height: 442px !important;
    }

    .form-title {
        font-size: 25px;
    }

    .login-form .form-control::-webkit-input-placeholder {
        font-size: 14px;
    }

    .login-form .form-control::placeholder {
        font-size: 14px;
    }

    .btn-forgot-pass {
        font-size: 17px;
    }

}

@media (min-width: 1025px) and (max-width: 1040px) {
    .header-call {
        margin-right: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1250px) {
    .thumbail .col {
        flex-basis: 33.33% !important;
        flex-grow: 0 !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px;
        margin: 1.75rem auto;
        width: 90%;
    }
}

@media(max-width:1400px) {

    .login-title {
        font-size: 70px;
    }

    .login-box {
        padding: 109px 34px 0;
    }

    .content-box {
        top: 100px;
    }

    .login-subtitle {
        font-size: 16px;
        line-height: 26px;
    }
}

@media(max-width:1281px) {
    .login-form {
        margin: 0 !important;
        padding: 30px;
    }

    .login-box {
        padding: 25% 34px;
        padding-top: 100px;
    }

    .content-box {
        top: 14%;
        left: 10%;
    }
}

@media screen and (max-width: 1024px) {

    .xs-hide {
        display: none !important;
    }

    .xs-100 {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .login-box {
        vertical-align: middle;
        padding: 34px;
    }

    .login-form {
        margin: 0 auto !important;
        padding: 30px;
        width: 55%;
    }

    .i-pad-button {
        display: block;
        border-top: 1px solid rgb(0 0 0 / 39%);
        margin-top: 30px;
        text-align: center;
    }

    .login-subtitle {
        color: #000;
        text-align: center;
    }

    .col-form-label {
        max-width: 100%;
    }

    .order-two {
        order: 2;
    }

    .order-one {
        order: 1;
    }

    .graph-buttons {
        margin-top: 3rem;
    }

    .dropdown-menu.show {
        top: 70px !important;
    }

    .dropdown-menu.dropdown-menu-right.dropdown-menu.show {
        -webkit-transform: translate3d(-9rem, -0.5rem, 0rem) !important;
                transform: translate3d(-9rem, -0.5rem, 0rem) !important;
    }

    .Quick.Filter {
        width: 140px;
        height: 50px;
    }

    /* ==== New Css Added === */

    .sm-block {
        display: block !important;
    }

    .md-block {
        display: none !important;
    }

    .navbar-user {
        /* display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around; */
        position: relative;
        top: 0;
    }

    .profile-dropdown {
        padding: 0;
        margin-left: 0;
    }

    .newdropdown {
        -webkit-transform: translate3d(-67%, 0, 0) !important;
                transform: translate3d(-67%, 0, 0) !important;
    }

    table.dataTable thead th.sorting:after,
    table.dataTable thead th.sorting_asc:after,
    table.dataTable thead th.sorting_desc:after {
        top: 12px !important;
    }

    .notification-badge.notification-badge-number {
        top: -8px !important;
        right: -6px !important;
    }

    a#notification\ mobile {
        display: block !important;
        font-size: 22px;
        margin-right: 15px;
        position: relative;
    }

    #notification\ mobile {
        background-color: unset !important;
        display: none !important;
    }

    .thumbail .col {
        flex-basis: 50%;
        flex-grow: 0;
    }

    .navbar-light .navbar-brand {
        width: 78%;
    }

    .navbar-light .navbar-brand {
        width: 78%;
        margin-top: 4rem;
    }

    .navbar-expand-md>.container-fluid {
        flex-wrap: wrap;
    }

    .d-md-none {
        display: block !important;
    }

    .navbar-expand-md .navbar-toggler {
        display: block;
    }

    .navbar-expand-md .navbar-collapse {
        width: 100%;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: column;
    }

    .navbar-expand-md .navbar-collapse.collapse:not(.show) {
        display: none !important;
    }

    .navbar-brand-img,
    .navbar-brand>img {
        max-height: 3rem;
    }

    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
        padding: 1.1rem 0rem;
    }

    .header-call,
    .lift {
        display: none;
    }

    .navbar-vertical.navbar-expand-md.fixed-left {
        background-color: #eff2ff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 999;
    }

    .Summary .col {
        max-width: 50%;
    }

    .active-hours h3,
    .active-hours span {
        font-size: 14px;
    }

    .active-hours span {
        font-size: 27px;
    }

    .active-summery .icons i {
        display: none;
    }

    .active-hours {
        margin: 0px !important;
    }

    h4.card-header-title {
        font-size: 25px;
        font-weight: bold;
    }

    .main-content {
        margin-top: 100px;
    }

    .navbar-brand-img,
    .navbar-brand>img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        transition: all 0.4s ease;
    }

    .navbar-brand .black-logo {
        display: inline-block;
    }

    .navbar-brand .white-logo {
        opacity: 0;
    }

    .navbar-light .navbar-toggler {
        border: none;
    }

    body:not(.show-menu) .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(80,77,166)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
    }

    .show-menu {
        overflow: hidden;
    }

    .show-menu .navbar-vertical.navbar-expand-md.fixed-left {
        background-color: #43283a;
        height: 100vh;
        align-items: flex-start;
    }

    .show-menu .navbar-brand .black-logo {
        opacity: 0;
    }

    .show-menu .navbar-brand .white-logo {
        opacity: 1;
        transition-delay: 0.2s;
    }

    h4.ac-summery-title,
    .Active-summery-title {
        font-size: 22px;
        margin-bottom: 30px;
    }

    .active-summery {
        height: 100px;
    }

    .tutor-Imformations .btn-link::after {
        right: 25px;
    }

    .tutor-Imformations .btn-link {
        font-size: 20px;
    }

    .btn-bang-save {
        padding: 0;
        margin-bottom: 0rem;
        margin-top: 0rem;
    }

    .bang-buttons {
        display: flex;
        justify-content: center;
    }

    h4.Active-summery-title {
        text-align: center;
    }

    .Active-Enrollment p {
        font-size: 14px;
    }

    a.bell.bang-bell {
        display: block;
        background-color: transparent;
        padding: 0px;
        margin-right: 12px;
    }

    a.bell {
        display: none;
    }

    .navbar-brand {
        margin-right: 0px !important;
    }

    .show-menu .fb-bell:before {
        color: #fff;
    }

    .table td,
    .table th {
        font-size: 14.4px;
    }

    .bang-bell::before {
        content: '';
        position: absolute;
        right: 11%;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 2px solid #fff;
        top: 23px;
    }

    .bell::before {
        background-color: red;
    }

    /*===== Login css ======*/

    .form-title {
        font-size: 25px;
    }

    .login-form .form-control::-webkit-input-placeholder {
        font-size: 14px;
    }

    .login-form .form-control::placeholder {
        font-size: 14px;
    }

    .button-box .btn-submit {
        font-size: 18px;
        width: 100%;
    }

    .btn-forgot-pass {
        margin-top: 10px;
        font-size: 16px;
    }

}

@media screen and (min-width: 800px) and (max-width: 1000px) and (orientation: landscape) {
    .show-menu .navbar-brand-img {
        top: 12% !important;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) and (orientation: landscape) {
    .show-menu .navbar-brand-img {
        top: 6% !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .Summary .col {
        max-width: 100%;
    }

    .show-menu .navbar-brand-img {
        top: 4% !important;
    }

    .table th {
        padding: 11px;
    }

    .navbar-light .navbar-user {
        margin-right: -15px;
    }
}

@media screen and (max-width:768px) {
    .accordion>.card {
        padding: 5px;
    }

    /* #chartContainer {
        height: 300px !important;
    } */

    .form-control {
        width: 100% !important;
    }

    .tutor-Imformations .btn-link::after {
        top: 8px !important;
    }

    .profile-dropdown {
        margin-top: 0px;
    }

    /*===== Login css ======*/

    .xs-100 {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .xs-hide {
        display: none !important;
    }

    .form-title {
        font-size: 22px;
    }

    .login-form .form-control {
        height: 44px;
    }

    .login-form .btn-pass-view {
        height: 44px;
    }

    .login-form .form-control::-webkit-input-placeholder {
        font-size: 13px;
    }

    .login-form .form-control::placeholder {
        font-size: 13px;
    }

    .btn-forgot-pass {
        margin-top: 10px;
        font-size: 14px;
    }

    .button-box .btn-submit {
        font-size: 16px;
        height: 36px;
    }

}

@media screen and (max-width:767px) {

    .login-subtitle {
        font-size: 14px;
        line-height: 19px;
        margin: 15px 0;
    }

    .login-form label.label-control {
        top: 5px;
    }

    .image-radio {
        padding: 0 !important;
    }

    .Active-account-summery,
    .Enrollment-part {
        padding: 15px;
    }

    a#notification\ mobile {
        display: block !important;
        font-size: 22px;
    }

    .show-menu .d-md-none {
        display: none !important;
    }

    .show-menu a#notification\ mobile {
        display: none !important;
    }

    /* .show-menu .navbar-brand-img {
        left: 0px;
    } */

    #notification {
        background-color: unset !important;
        display: none !important;
    }

    .table td:first-child {
        border-right: 1px solid #f3f3f3;
    }

    .bang-bell::before {
        right: 21% !important;
    }

    thead {
        display: none;
    }

    tr,
    th,
    td {
        display: block;
        border: none;
    }

    tr {
        border-bottom: 2px solid #DEDEDE;
    }

    td:before {
        content: attr(data-heading);
        display: inline-block;
        width: 50%;
        color: #2D2D2D;
        font-weight: 700;
    }

    .btn-bang-save {
        padding: 10px;
        margin-bottom: 0rem;
        width: 90px;
        height: 45px;
    }

    span.text-icons {
        width: 50%;
        display: inline-block;
    }

    .table td {
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
        border: none;
        padding: 10px;
    }

    .Used-Hours {
        display: none;
    }

    canvas#graph {
        height: 248px !important;
        width: 248px !important;
    }

    .card-header>:first-child {
        font-size: 25px;
    }

    .navbar-light .navbar-brand {
        position: relative;
        height: 56px;
        display: block;
        /* width: 50% !important;*/
        margin-top: 1rem !important;
    }

    .header-title {
        display: none;
    }

    .btn-bang-save {
        margin-top: 0px;
        padding: 9px;
        font-size: 17px;
        margin-bottom: 2rem;
        margin-left: 9px;
    }

    /*==== Login page css ====*/

    .login-box {
        padding: 6% 20px;
    }

    /* ===== Main page Card css ==== */

    .card.total-hours {
        min-height: auto !important;
        max-height: 100% !important;
    }

    /* ==== Chart issues css ==== */

    #chartContainer {
        height: 100% !important;
        margin-bottom: -20px;
    }
}

@media screen and (max-width:600px) {
    .navbar-light .navbar-brand {
        width: 50% !important;
    }

    .login-form {
        padding: 20px;
        width: 100%;
    }

    .login-form label.label-control {
        top: 5px;
    }

    .btn-download {
        font-size: 14px;
    }

    .check {
        margin-bottom: 20px;
    }

}

@media screen and (max-width:320px) {
    .navbar-light .navbar-brand {
        width: 42% !important;
    }

    .active-hours h3 {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 280px) {
    .thumbail .col {
        flex-basis: 100%;
        flex-grow: 0;
    }

    .navbar-light .navbar-brand {
        width: 34% !important;
    }
}

.edTgaw div:first-child {
    white-space: normal !important;
    overflow: visible !important;
    text-overflow: initial !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181B !important;
    text-decoration: none;
    background-color: #F8F9FA !important;
}

a.dropdown-item p {
    margin-top: 0;
    margin-bottom: 0rem;
}

input[disabled]:hover {
    cursor: not-allowed;
}

h1.header-call a {
    color: #000;
    text-decoration: none;
}

/* .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    left: -31px !important;
} */

.sc-iwajpm.iMHbVh {
    margin-top: 6rem !important;
    color: #636060 !important;
    font-size: 0.8rem !important;
}

.sc-iwajpm.bHRuLM {
    margin-top: 6rem !important;
    color: #636060 !important;
    font-size: 0.8rem !important;
}

.react-datepicker__close-icon {
    height: 60% !important;
}

.react-datepicker-wrapper.datepicker-wrapper {
    display: block !important;
}

.datepicker-wrapper .input-group-text {
    position: absolute;
    right: 0;
    height: 38px;
    background-color: transparent;
    border: transparent;
    z-index: 1215;
    cursor: pointer;
}

.datepicker-wrapper .react-datepicker__close-icon {
    top: 8px;
    right: 40px;
    z-index: 1215;
}

.datepicker-wrapper .input-group>.custom-select:not(:last-child),
.datepicker-wrapper .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    left: -44% !important;
    right: -44% !important;
}

.calander-icon {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABHNCSVQICAgIfAhkiAAAApZJREFUSEvNVk12EkEQrhpcqBuTEwRPIO4NjCcI2QU2dk7geALwBJITpN2Q7CQncAgeYLwBOYG4ARcy7VcDPa+Z12FmfNEXdlNVXV/9fFQVk+d39kaFQYO+Ukrn45nWrknvWHU5oC8mpdOrmZ4UdBF0g3RNp9ffdOzzzT5hv62GxDQwhqZXtzp0bayODH0c3+rhDmBbxczU8emsXQ6IyCMK6ASCFpQHvkBqyhaGKGFDEwR2sQPY66gEQK9qOqxjniyZ3k5iveBeW41QhveI5ienFKWG5uiDguwdZN/NmiLXc64z9Bl93OkvN2gkgaMVmS5gapogk72A7ALtidhm55JAygvHn+QhjJSvh7D/ANKMCj3UEqhLNksy2CXjqX7N/Y5CIkRI+VBStg6Eqb+eUOLKRNcN1cHT39TysdCnE9lzQz/kLQA5B5SPOk2pY2uTejyAUoZnKZ+sAnNjS5r1gtiMZ5c3NruizPdObEsz7B8rhf/kpW2+03jaJyu+s4E9PsCMWWvqLhs0sSXNohc275H53lUqaR0GVrEtLWlV0mwy4g64JPOXyHCybJhp8b9bCliFNOL/nhm8wBB56YI+CKDdmTKDsY6SLEOmlszN4h4tBaxCmhzQ2ZlYBN59WApYhQi1AMFwVGIhF4J3lhZJY78lEDt98jMEjmTRbiq6Xd6e08Qm4QXMSbM9I/JveeU485FGduiKKSwydS9g1kMs4yWWpzy0PS3+8eVbMnVb4FtpZ6Fqis11rOf/ZT2hEguU22A9HQrgHOBHvrOvCnnKbJzBfwfAptw0m7NAGp/y0F0/Zc7K9FuwAexa9lzh7QkgLDsqc/C3ehkOIFIzu9rEyYb2uK7ArgcGvkNm8SqgyLL2n90x91XjD/liSDsOTlEHAAAAAElFTkSuQmCC) no-repeat;
    background-size: 25px;
    width: 25px;
    height: 25px;
    border-width: thin;
    margin-top: 0;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #43283a !important;
}

.avatar.avatar-sm.avatar-online span {
    font-size: 18px;
    margin-top: 10px;
    display: block;
    color: #43283a;
    font-weight: bold;
}

.download-button.focus,
.download-button:focus {
    outline: 0;
    box-shadow: 1px 1px 8px 5px #49505742;
}

span.view-note {
    cursor: pointer;
}

.space-left {
    padding-left: 0.6rem;
}

/* css for loader of datatable  */
.sc-kfYoZR.gYmIHQ div {
    font-size: 0.9rem !important;
    color: #000000ab !important;
    font-weight: 300 !important;
}

.sc-kfYoZR.sVsAp div {
    font-size: 0.9rem !important;
    color: #000000ab !important;
    font-weight: 300 !important;
}

/* css class call after build project */
.custome-loader {
    font-size: 0.9rem !important;
    color: #000000ab !important;
    font-weight: 300 !important;
    margin-top: 2rem;
    display: "block";
    position: "absolute";
    background: "rgba(255,255,255,0.8)";
    transition: "all .3s ease";
    text-align: "center";
    top: 0;
    bottom: 0;
}

.sc-iwajpm.cKNJLz {
    margin-top: 6rem !important;
    color: #636060 !important;
    font-size: 0.9rem !important;
}

.sc-iwajpm.cOFEIC {
    margin-top: 6rem !important;
    color: #636060 !important;
    font-size: 0.9rem !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* total hours card */


.total-hours .card-body {
    padding: 10px;
}

/* center request call on mobile*/
@media (max-width: 768px) {

    .right-side,
    .centers {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
    }
    .mobile-phone-number, .mobile-button-bg {
      display: block;
    }
    .mobile-stack {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center !important; 
    }
    .mobile-phone-number, .mobile-button-bg {
      width: 100%;
      text-align: center;
      margin: 0;
    }
    .mobile-button-bg {
      align-self: center !important;
    }
  }
